import { gql } from '@apollo/client';

export const listQueryBuilder = (
  listQueryName,
  mappingType,
  clientQuery,
  listName
) => {
  let queryDimensions = '';
  let whereString = '';
  let orderByString = 'orderBy: { list_value: asc }';
  let argumentsString = '';
  let graphQlQuery = '';

  // list query names
  // GET_S2_LIST_NAMES
  // GET_S2_LIST_GLOBAL_NAMES
  // GET_S2_LIST_CLIENT_NAMES

  // GET_S2_LIST_VALUES
  // GET_S2_LIST_GLOBAL_VALUES
  // GET_S2_LIST_CLIENT_VALUES

  // GET_S2_OTHER_LIST_NAMES
  // GET_S2_OTHER_GLOBAL_LIST_NAMES
  // GET_S2_OTHER_CLIENT_LIST_NAMES

  // GET_S2_LIST_DATA_BY_LIST_NAME
  // GET_S2_LIST_DATA_GLOBAL_BY_LIST_NAME
  // GET_S2_LIST_DATA_CLIENT_BY_LIST_NAME

  // GET_S2_LIST_DATA_WITHOUT_LIST_NAME
  // GET_S2_LIST_DATA_GLOBAL_WITHOUT_LIST_NAME
  // GET_S2_LIST_DATA_CLIENT_WITHOUT_LIST_NAME

  switch (listQueryName) {
    case 'GET_S2_LIST_NAMES': {
      if (clientQuery !== '' /* && clientQuery !== 'Global' */) {
        whereString = `where: {_eq: { mapping_type: "${mappingType}", client_name: "${clientQuery}"}}`;
      } else {
        whereString = `where: {_eq: { mapping_type: "${mappingType}"}}`;
      }
      argumentsString = `( distinct_on: list_name ${whereString})`;
      queryDimensions = 'list_name';
      break;
    }
    case 'GET_S2_LIST_GLOBAL_NAMES': {
      whereString = `where: {_eq: { mapping_type: "${mappingType}", client_name: "Global"}}`;
      argumentsString = `( distinct_on: list_name ${whereString})`;
      queryDimensions = 'list_name';
      break;
    }
    case 'GET_S2_LIST_CLIENT_NAMES': {
      whereString = `where: {_eq: { mapping_type: "${mappingType}"} _neq: { client_name: "Global"}}`;
      argumentsString = `( distinct_on: list_name ${whereString})`;
      queryDimensions = 'list_name';
      break;
    }
    //
    //
    //
    case 'GET_S2_LIST_VALUES': {
      if (clientQuery !== '' && clientQuery !== 'Global') {
        whereString = `where: {_eq: { mapping_type: "${mappingType}", client_name: "${clientQuery}" }}`;
      } else {
        whereString = `where: {_eq: { mapping_type: "${mappingType}" }}`;
      }
      argumentsString = `(${whereString} ${orderByString})`;
      queryDimensions =
        'list_id list_value list_alt_value list_name sequence_no created_ts last_updated_ts created_by last_updated_by mapping_type table_id visible item_author list_author uses removed is_core_item item_desc list_desc client_name list_order_sequence user_defined_columns  { name type value column_id column_type_id branch_id cell_id }';
      break;
    }
    case 'GET_S2_LIST_GLOBAL_VALUES': {
      whereString = `where: {_eq: { mapping_type: "${mappingType}", is_core_item: true, client_name: "Global" }}`;
      argumentsString = `(${whereString} ${orderByString})`;
      queryDimensions =
        'list_id list_value list_alt_value list_name sequence_no created_ts last_updated_ts created_by last_updated_by mapping_type table_id visible item_author list_author uses removed is_core_item item_desc list_desc client_name list_order_sequence user_defined_columns  { name type value column_id column_type_id branch_id cell_id }';
      break;
    }
    case 'GET_S2_LIST_CLIENT_VALUES': {
      whereString = `where: {_eq: { mapping_type: "${mappingType}"} _neq: { client_name: "Global" }}`;
      argumentsString = `(${whereString} ${orderByString})`;
      queryDimensions =
        'list_id list_value list_alt_value list_name sequence_no created_ts last_updated_ts created_by last_updated_by mapping_type table_id visible item_author list_author uses removed is_core_item item_desc list_desc client_name list_order_sequence user_defined_columns  { name type value column_id column_type_id branch_id cell_id }';
      break;
    }
    //
    //
    //
    case 'GET_S2_OTHER_LIST_NAMES': {
      if (clientQuery !== '' /* && clientQuery !== 'Global' */) {
        whereString = `where: {_eq: { mapping_type: "${mappingType}", client_name: "${clientQuery}"} _neq: { list_name: "${listName}" }}`;
      } else {
        whereString = `where: {_eq: { mapping_type: "${mappingType}"} _neq: { list_name: "${listName}" }}`;
      }
      argumentsString = `( distinct_on: list_name ${whereString})`;
      queryDimensions = 'list_name';
      break;
    }
    case 'GET_S2_OTHER_GLOBAL_LIST_NAMES': {
      whereString = `where: {_eq: { mapping_type: "${mappingType}", client_name: "Global"} _neq: { list_name: "${listName}" }}`;
      argumentsString = `( distinct_on: list_name ${whereString})`;
      queryDimensions = 'list_name';
      break;
    }
    case 'GET_S2_OTHER_CLIENT_LIST_NAMES': {
      whereString = `where: {_eq: { mapping_type: "${mappingType}"} _neq: { client_name: "Global", list_name: "${listName}" }}`;
      argumentsString = `( distinct_on: list_name ${whereString})`;
      queryDimensions = 'list_name';
      break;
    }
    //
    //
    //
    case 'GET_S2_LIST_DATA_BY_LIST_NAME': {
      if (clientQuery !== '' /* && clientQuery !== 'Global' */) {
        whereString = `where: {_eq: { mapping_type: "${mappingType}", client_name: "${clientQuery}" list_name: "${listName}" }}`;
      } else {
        whereString = `where: {_eq: { mapping_type: "${mappingType}" list_name: "${listName}" }}`;
      }
      argumentsString = `(${whereString} ${orderByString})`;
      queryDimensions =
        'list_id list_value list_alt_value list_name sequence_no created_ts last_updated_ts created_by last_updated_by mapping_type table_id visible item_author list_author uses removed is_core_item item_desc list_desc client_name list_order_sequence user_defined_columns { name type value column_id column_type_id branch_id cell_id }';
      break;
    }
    case 'GET_S2_LIST_DATA_GLOBAL_BY_LIST_NAME': {
      whereString = `where: {_eq: { mapping_type: "${mappingType}", client_name: "Global" list_name: "${listName}" }}`;

      argumentsString = `(${whereString} ${orderByString})`;
      queryDimensions =
        'list_id list_value list_alt_value list_name sequence_no created_ts last_updated_ts created_by last_updated_by mapping_type table_id visible item_author list_author uses removed is_core_item item_desc list_desc client_name list_order_sequence user_defined_columns { name type value column_id column_type_id branch_id cell_id }';
      break;
    }
    case 'GET_S2_LIST_DATA_CLIENT_BY_LIST_NAME': {
      whereString = `where: {_eq: { mapping_type: "${mappingType}", list_name: "${listName}"} _neq: {client_name: "Global"}}`;
      argumentsString = `(${whereString} ${orderByString})`;
      queryDimensions =
        'list_id list_value list_alt_value list_name sequence_no created_ts last_updated_ts created_by last_updated_by mapping_type table_id visible item_author list_author uses removed is_core_item item_desc list_desc client_name list_order_sequence user_defined_columns { name type value column_id column_type_id branch_id cell_id }';
      break;
    }
    //
    //
    //
    case 'GET_S2_LIST_DATA_WITHOUT_LIST_NAME': {
      if (clientQuery !== '' /* && clientQuery !== 'Global' */) {
        whereString = `where: {_eq: { mapping_type: "${mappingType}", client_name: "${clientQuery}"} _neq: { list_name: "${listName}" }}`;
      } else {
        whereString = `where: {_eq: { mapping_type: "${mappingType}"} _neq: { list_name: "${listName}" }}`;
      }
      argumentsString = `(${whereString} ${orderByString})`;
      queryDimensions =
        'list_id list_value list_alt_value list_name sequence_no created_ts last_updated_ts created_by last_updated_by mapping_type table_id visible item_author list_author uses removed is_core_item item_desc list_desc client_name list_order_sequence user_defined_columns { name type value column_id column_type_id branch_id cell_id }';
      break;
    }
    case 'GET_S2_LIST_DATA_GLOBAL_WITHOUT_LIST_NAME': {
      whereString = `where: {_eq: { mapping_type: "${mappingType}", client_name: "Global"} _neq: { list_name: "${listName}" }}`;
      argumentsString = `(${whereString} ${orderByString})`;
      queryDimensions =
        'list_id list_value list_alt_value list_name sequence_no created_ts last_updated_ts created_by last_updated_by mapping_type table_id visible item_author list_author uses removed is_core_item item_desc list_desc client_name list_order_sequence user_defined_columns { name type value column_id column_type_id branch_id cell_id }';
      break;
    }
    case 'GET_S2_LIST_DATA_CLIENT_WITHOUT_LIST_NAME': {
      whereString = `where: {_eq: { mapping_type: "${mappingType}"} _neq: { client_name: "Global" list_name: "${listName}" }}`;
      argumentsString = `(${whereString} ${orderByString})`;
      queryDimensions =
        'list_id list_value list_alt_value list_name sequence_no created_ts last_updated_ts created_by last_updated_by mapping_type table_id visible item_author list_author uses removed is_core_item item_desc list_desc client_name list_order_sequence user_defined_columns { name type value column_id column_type_id branch_id cell_id }';
      break;
    }
    default:
      break;
  }
  graphQlQuery = gql`
  {
    s2_lists ${argumentsString} {
      ${queryDimensions}
    }
  }`;

  return graphQlQuery;
};

import { Link } from 'react-router-dom';
import ChevronDownIcon from '../../components/shared/icons/ChevronDown';

const NavigationItems = ({
  login,
  navData,
  location,
  addNewCampaign,
  setupDropdownRef,
  setAddNewCampaign,
  showImportCsvModal,
  isSetupDropdownOpen,
  setShowImportCsvModal,
  setIsSetupDropdownOpen,
  showImportConfirmationModal,
  setShowImportConfirmationModal,
}) => {
  return (
    <nav className='topnav-navigation'>
      {navData?.mappings?.readPermissions.includes(
        login.permissions.find((perm) => {
          return perm.startsWith('Role.') || perm.startsWith('eu-west-2');
        })
      ) && (
        <Link
          className='topnav-navigation_item h-100'
          style={{
            borderBottom:
              location.pathname.startsWith('/mappings') &&
              '2px solid var(--intuita-main)',
            fontWeight: location.pathname.startsWith('/mappings') && 'bold',
          }}
          onClick={() => {
            if (addNewCampaign) setAddNewCampaign(false);
            if (showImportCsvModal) setShowImportCsvModal(false);
            if (showImportConfirmationModal)
              setShowImportConfirmationModal(false);
          }}
          to='/mappings'
        >
          Mappings
        </Link>
      )}
      {navData?.taxonomy?.readPermissions.includes(
        login.permissions.find((perm) => {
          return perm.startsWith('Role.') || perm.startsWith('eu-west-2');
        })
      ) && (
        <Link
          className='topnav-navigation_item'
          style={{
            borderBottom:
              location.pathname.startsWith('/taxonomies') &&
              '2px solid var(--intuita-main)',
            fontWeight: location.pathname.startsWith('/taxonomies') && 'bold',
          }}
          onClick={() => {
            if (showImportCsvModal) setShowImportCsvModal(false);
          }}
          to='/taxonomies'
        >
          Taxonomies
        </Link>
      )}
      {navData?.lists?.readPermissions.includes(
        login.permissions.find((perm) => {
          return perm.startsWith('Role.') || perm.startsWith('eu-west-2');
        })
      ) && (
        <Link
          className='topnav-navigation_item'
          style={{
            borderBottom:
              location.pathname.startsWith('/lists') &&
              '2px solid var(--intuita-main)',
            fontWeight: location.pathname.startsWith('/lists') && 'bold',
          }}
          onClick={() => {
            if (showImportCsvModal) setShowImportCsvModal(false);
          }}
          to='/lists'
        >
          Lists
        </Link>
      )}

      {(navData?.clientManagement?.readPermissions.includes(
        login.permissions.find((perm) => {
          return perm.startsWith('Role.') || perm.startsWith('eu-west-2');
        })
      ) ||
        navData?.tableDefinitions?.readPermissions.includes(
          login.permissions.find((perm) => {
            return perm.startsWith('Role.') || perm.startsWith('eu-west-2');
          })
        )) && (
        <div className='dd' ref={setupDropdownRef}>
          <div
            className='dd-setup_toggle'
            onClick={() => {
              setIsSetupDropdownOpen(!isSetupDropdownOpen);
            }}
          >
            <div
              style={{
                background: isSetupDropdownOpen ? '#232751' : null,
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                padding: '.5rem',
                height: '100%',
              }}
            >
              <span
                className='dd-setup_label'
                style={{
                  color: 'white',
                  fontWeight: isSetupDropdownOpen ? 'bold' : null,
                }}
              >
                Setup
              </span>

              <span>
                <ChevronDownIcon stroke='#BDBDBD' />
              </span>
            </div>
          </div>
          {isSetupDropdownOpen && (
            <div className='dd-setup_menu overflow-style'>
              {navData?.clientManagement?.readPermissions.includes(
                login.permissions.find((perm) => {
                  return (
                    perm.startsWith('Role.') || perm.startsWith('eu-west-2')
                  );
                })
              ) && (
                <Link
                  onClick={() => {
                    if (showImportCsvModal) setShowImportCsvModal(false);
                    if (isSetupDropdownOpen === true) {
                      setIsSetupDropdownOpen(false);
                    }
                  }}
                  className='dd-setup_item'
                  to='/client-management'
                >
                  Client Management
                </Link>
              )}
              {navData?.tableDefinitions?.readPermissions.includes(
                login.permissions.find((perm) => {
                  return (
                    perm.startsWith('Role.') || perm.startsWith('eu-west-2')
                  );
                })
              ) && (
                <Link
                  onClick={() => {
                    if (showImportCsvModal) setShowImportCsvModal(false);
                    if (isSetupDropdownOpen === true) {
                      setIsSetupDropdownOpen(false);
                    }
                  }}
                  className='dd-setup_item'
                  to='/table-definitions'
                >
                  Mapping Setup
                </Link>
              )}
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

export default NavigationItems;
